import ContactForm from "../../components/contactForm/ContactForm";
import GoogleMap from "../../components/googleMap/GoogleMap";
import HelmetSeo from "../../components/helmetSeo/HelmetSeo";
import { Link } from "react-router-dom";
import './contact.scss';


const Contact = () => {
    return (
        <div className="contact">
            <HelmetSeo
                title="Kontakty"
                description="Kontaktujte nás prostřednictvím kontaktního formuláře nebo na uvedených telefonních číslech a emailu. Jsme zde pro vás, ať už máte jakékoli dotazy."
                keywords="kontakt, kontaktujte nás, R-moss, telefon, email, podpora"
                url="https://r-moss.cz/kontakt"
            />
            <div className="contactForm  d-flex d-row text-center p-md-5 p-2 py-5">
                <div className="d-flex flex-column align-items-start col gap-5 text-white py-5 bg-primary px-md-5 px-3 info">
                    <h2 className="fw-bold">Máte jákkékoliv dotazy?</h2>
                    <p className="">Neváhejte se na nás obrátit. Jsme tu, abychom vám pomohli a odpověděli na všechny vaše dotazy.</p>
                    <div className="d-flex flex-column align-items-start fs-5 gap-3 pb-lg-5">
                        <a href="tel:+420603908887"><img className="me-4" src="../../../icons/phone.png" alt="ikona telefonu" />+420 603 908 887</a>
                        <a href="mailto:info@r-moss.cz"><img className="me-4" src="../../../icons/mail.png" alt="ikona emailu" />info@r-moss.cz</a>
                        <a><img className="me-4" src="../../../icons/address.png" alt="ikona adresy" />Meziřícká 297, 591 01 Velká Bíteš</a>
                    </div>
                    <div className="pt-5 mt-lg-5 d-flex gap-3 gap-md-4">
                        <img src="../../../icons/facebook-b.png" alt="Facebook ikona" />
                        <img src="../../../icons/instagram-b.png" alt="Instagram ikona" />
                        <img src="../../../icons/linkedin-b.png" alt="Linkedin ikona" />
                    </div>
                </div>
                <div className="form col">
                    <ContactForm />
                </div>
            </div>
            <div className="map">
                <GoogleMap />
            </div>
        </div>
    );
}

export default Contact;