import "./productPacking.scss"

const ProductPacking = () => {
    return (
        <div className="productPacking">
            <div className="pb-5">
                <h2 className="text-center">Varianty balení</h2>
                <p className="text-center mb-5">Náš kuličkový mech standardně dodáváme na paletách ve dvou velikostech:</p>
            </div>


            <div className="productBox pb-5 d-md-flex justify-content-center gap-5">
                <div className="p-5">
                    <img src="/media/bolmos-menu.png" alt="malé balení kuličkového mechu" />
                </div>
                <div className="item-2 d-flex flex-column">
                    <h3 className="pb-3">Malá krabička</h3>
                    <ul>
                        <li>Tlouštka mechu cca 2-7 cm;</li>
                        <li>Průměr 5-15 cm</li>
                        <li>Rozměry krabice: 38,5x29x9,5 cm</li>
                        <li>1 krabice = cca 0,15 m2 = potřeba cca 7 krabic na m2</li>
                        <li>4 malé krabice jsou dodávány ve vnější krabici 40x60x21 cm</li>
                        <li>160 malých krabic na paletě / 40 vnějších krabic</li>
                    </ul>
                </div>
            </div>

            <div className="productBox pb-5 d-md-flex justify-content-center gap-5">
                <div className="item-2">
                    <h3 className="pb-3">Velká krabička</h3>
                    <ul>
                        <li>Tlouštka mechu cca 3-10 cm;</li>
                        <li>Průměr 5-25 cm</li>
                        <li>Rozměry krabice: 40x60x21 cm</li>
                        <li>1 krabice kuličkového mechu: cca 0,6-0,75 m2</li>
                        <li>40-44 krabic na paletě</li>
                    </ul>
                </div>
                <div className="p-5">
                    <img src="/media/bolmos-large.png" alt="velké balení kuličkového mechu" />
                </div>
            </div>

        </div>
    );
}

export default ProductPacking;