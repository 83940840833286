const GoogleMap = () => {

    const mapSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2581.7090057577525!2d15.8744132760123!3d49.67861984263685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470da40fc10eb781%3A0x71f1cb8087161550!2zVm9qbsWvdiBNxJtzdGVjIDMyMiwgNTkxIDAxIFZvam7Fr3YgTcSbc3RlYw!5e0!3m2!1scs!2scz!4v1720797394944!5m2!1scs!2scz`;
    
    return(
        <div style={{ width: '100%', height: '100%' }}>
            <iframe
                title="Google Map"
                width="100%"
                height="450px"
                frameBorder="0"
                style={{ border: 0 }}
                src={mapSrc}
                loading="lazy"
                allowFullScreen
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    );
}
 
export default GoogleMap;