import { Link, NavLink } from 'react-router-dom';
import "./header.scss"
import "./headerHam.scss"
import "./stickyHeader.js"

const Header2 = () => {
    
    return (
        <header id="header">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid px-md-5 px-3">
                    {/* Logo */}
                    <Link className="navbar-brand" to="/">
                        <img className="logo" alt="logo společnosti R-moss" src="../../../media/logo-small.png" />
                    </Link>

                    {/* Navbar Toggle Button for Mobile */}

                    <div id="nav-icon4" onClick={
                        function () {
                            document.getElementById('nav-icon4').classList.toggle('open')
                        }
                    } className="d-lg-none no-select"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    {/* Navbar Links */}
                    <div className="collapse navbar-collapse justify-content-between gap-5 " id="navbarNav">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">
                            <li className="nav-item">
                                <NavLink className="nav-link a" to="/">Úvod</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link a" to="/o-nas">O nás</NavLink>
                            </li>

                            {/* Dropdown for Produkty */}
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle a" to="/produkty" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Produkty
                                </NavLink>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li>
                                        <Link className="dropdown-item a" to="/kulickovy">Kuličkový mech</Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item a" to="/plochy">Plochý mech</Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item a" to="/lepidlo">Lepidlo na mech</Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link a" to="/faq">FAQ</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link a" to="/kontakty">Kontakty</NavLink>
                            </li>
                        </ul>
                        <div className="header-icon d-flex align-items-center d-none d-xl-block">
                            <Link to="#">
                                <img className="footer-icon me-3" src="/icons/phone.png" alt="mobil ikona" />
                            </Link>
                            <Link to="#">
                                <img className="footer-icon me-3" src="/icons/instagram-b.png" alt="instagram ikona" />
                            </Link>
                            <Link to="#">
                                <img className="footer-icon me-3" src="/icons/linkedin-b.png" alt="linkedIn ikona" />
                            </Link>
                        </div>
                        <Link className='btn btn-primary text-white' to='kontakty'>Kontakty</Link>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header2;
