import "./table.scss";

const Table = () => {
    return (
        <div className="table table-responsive">
            <h2 className="fs-5 text-muted py-3">Který mech se nejvíce hodí do vašeho interiéru?</h2>
            <table class="table table-striped">
                <tbody>
                    <tr className="">
                        <th></th>
                        <th>Kuličkový mech</th>
                        <th>Plochý mech</th>
                    </tr>
                    <tr>
                        <td>Pohlcení zvuku</td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                        <td>
                            <span></span>
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>Počet barev</td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>

                        </td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>Cena</td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                        <td>
                            <span></span>
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>Odolnost</td>
                        <td>
                            <span></span>
                            <span></span>
                        </td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>Intenzita vůně</td>
                        <td>
                            <span></span>
                            <span></span>
                        </td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>Uvolńování barviva</td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>Technologie upevnění</td>
                        <td>Lepidlo</td>
                        <td>Lepidlo</td>
                    </tr>
                    <tr>
                        <td>Zpomalovač hoření</td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>Antistatický/odpuzování nečistot</td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>Barevná stálost</td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>Udržitelnost</td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                        <td>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td>Tloušťka materiálu</td>
                        <td>2-7 cm</td>
                        <td>1-3 cm</td>
                    </tr>

                </tbody>
            </table>
        </div>
    );
}

export default Table;