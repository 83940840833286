import PhotoGallery from "../../components/photoGallery/PhotoGallery";
import ProductVariation from "../../components/productVariation/ProductVariation";
import Table from "../../components/table/Table";
import ProductPacking from "../../components/productPacking/ProductPacking";
import InfiniteScroll from "../../components/autoScroll/InfiniteScroll";
import HelmetSeo from "../../components/helmetSeo/HelmetSeo";

import "./bolmos.scss";
import PurchaseInfo from "../../components/purchaseInfo/PurchaseInfo";




const Bolmos = () => {
    return (
        <div className="bolmos">
            <HelmetSeo
                title="Kuličkový mech"
                description="Objevte krásu a všestrannost kuličkového mechu od R-moss.cz. Ideální pro kreativní a ekologická interiérová řešení. Vytvořte jedinečné mechové obrazy nebo dekorace."
                keywords="kuličkový mech, přírodní mech, ekologická dekorace, mechové obrazy, R-moss, interiérový design, udržitelnost"
                url="http://localhost:3000/kulickovy"
            />
            <div className="container">
                <div className="py-5 d-flex justify-content-between  row">
                    <div className="col-lg-7">
                        <h2 className="pb-5">
                            Mech <span className="text-primary fw-bold">Bolmoss</span>
                        </h2>
                        <p>Polštářový mech, známý také jako jehelníček nebo 'Leucobryum glaucum', je vzácným klenotem evropských lesů. Tento nádherný listnatý mech tvoří okouzlující, kulaté polštáře o tloušťce 3 až 10 centimetrů, které svým vzhledem a hebkostí připomínají přírodní umělecká díla.</p>
                        <p>Náš Bolmoss je dostupný ve třech odstínech: 'Spring Green', 'Moss Green' a 'Forest Green'. Tyto barvy vytvářejí dokonalé pozadí pro navodění klidné a přirozené atmosféry v jakémkoli prostoru.</p>
                        <p>Objevte kouzlo tohoto výjimečného mechu a přineste si kousek přírody přímo domů.</p>
                    </div>
                    <div className="col-lg-5">
                        <h3 className="pb-3 text-primary">Barvy</h3>
                        <div className="d-flex justify-content-between">
                            <div className="item-1">
                                <ProductVariation produckImage="/media/bolmos-spring.png" name="SpringGreen" />
                            </div>
                            <div className="item-2">
                                <ProductVariation produckImage="/media/bolmos-moss.png" name="MossGreen" />
                            </div>

                            <div className="item-3">
                                <ProductVariation produckImage="/media/bolmos-forest.png" name="ForestGreen" />
                            </div>

                        </div>

                    </div>

                </div>
            </div>


            <div className="py-5 container">
                <ProductPacking />
            </div>
            <div className="container">
                <Table />
            </div>
            <div className="mb-3">
                <InfiniteScroll />
            </div>
            <PurchaseInfo />
            <div className="container pb-5">
                <PhotoGallery />
            </div>
        </div>
    );
}

export default Bolmos;