import { Link } from "react-router-dom";

const Page404 = () => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center py-5 my-5">
            <h1 className="text-primary display-1 fw-bold">404</h1>
            <p>Stránka nenalezena!</p>
            <Link className="btn btn-primary text-white" to="/">Zpět na domovskou stránku</Link> 
        </div>
    );
}
 
export default Page404;