import { Link } from "react-router-dom";
import "./footer.scss"

const Footer = () => {
    return (

        <footer className="">
            <div className="d-flex row justify-content-between px-2 px-md-4 px-lg-5 pt-5 text-white bg-primary">

                <div className="d-flex flex-column col-md mb-5">
                    <img className="logo" src="/media/logo-main.png" alt="logo společnosti R-moss" />
                    
                    <div className="mt-3 d-flex">
                        <img className="footer-icon me-3" src="/icons/place-marker-w.png" alt="ikona adresy" />
                        <div>Tř. 1. máje 1621, 753 01 Hranice</div>
                    </div>
                    <div className="mt-3 d-flex">
                        <img className="footer-icon me-3" src="/icons/phone-w.png" alt="ikona telefonu" />
                        <div>+420 603 908 887</div>
                    </div>
                </div>
                <div className="d-flex flex-column col-md mb-5">
                    <span className="fw-bold fs-3">Sledujte nás</span>
                    <div className="mt-3">
                        <Link>
                            <img className="footer-icon me-3" src="/icons/instagram-w.png" alt="ikona instagramu" />
                            <span>Instagram</span>
                        </Link>
                    </div>
                    <div className="mt-3">
                        <Link>
                            <img className="footer-icon me-3" src="/icons/facebook-w.png" alt="ikona facebooku" />
                            <span>Facebook</span>
                        </Link>
                    </div>
                    <div className="mt-3">
                        <Link>
                            <img className="footer-icon me-3" src="/icons/linkedin-w.png" alt="ikona linkedinu" />
                            <span>Linkedin</span>
                        </Link>
                    </div>
                </div>
                <div className="d-flex flex-column col-md mb-5">
                    <span className="fw-bold fs-3">Nabízíme</span>
                    <div className="mt-3">
                        <Link to="/kulickovy">
                            <span>Kuličkový mech</span>
                        </Link>
                    </div>
                    <div className="mt-3">
                        <Link to="/plochy">
                            <span>Plochý mech</span>
                        </Link>
                    </div>
                    <div className="mt-3">
                        <Link to="/lepidlo">
                            <span>Lepidlo na mech</span>
                        </Link>
                    </div>
                </div>
                <div className="d-flex flex-column col-md mb-5 d-none d-lg-block">
                    <span className="fw-bold fs-3 ">Ostatní</span>
                    <div className="mt-3">
                        <Link to='/zasady-cookies'>
                            <span>Zásady cookies</span>
                        </Link>
                    </div>
                    <div className="mt-3">
                        <Link to="/kontakty">
                            <span>Kontakty</span>
                        </Link>
                    </div>
                    <div className="mt-3">
                        <Link to='/sitemap.xml'>
                            <span>Sitemap</span>
                        </Link>
                    </div>
                </div>



            </div>
            <div className="d-flex justify-content-between align-items-center p-1 text-white bg-dark copy text-uppercase">
                <span>Všechna práva vyhrazena</span>
                <span>vytvořeno doprojektu.cz</span>
            </div>


        </footer>
    );
}

export default Footer;