import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer"
import BackToTop from "../backToTop/BackToTop";
import Header2 from "../header/Header2";


const Layout = () => {
    return (
        <div className="Layout">
            <Header2 />
            <BackToTop />
            <Outlet />
            <Footer />
        </div>
    );
}
 
export default Layout;