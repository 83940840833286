import "./productPacking.scss"

const ProductPacking1 = () => {
    return (
        <div className="productPacking">
            <div className="pb-5">
                <h2 className="text-center">Varianty balení</h2>
                <p className="text-center mb-5">Náš plochý mech standardně dodáváme na paletách ve dvou velikostech:</p>
            </div>


            <div className="productBox pb-5 d-md-flex justify-content-center gap-5">
                <div className="p-5">
                    <img src="/media/flatmos-menu.png" alt="malé balení plochého mechu" />
                </div>
                <div className="item-2 d-flex flex-column">
                    <h3 className="pb-3">Malá krabička</h3>
                    <ul>
                        <li>Tlouštka mechu cca 1-2 cm;</li>
                        <li>Rozměry krabice: 38,5x29x9,5 cm</li>
                        <li>1 krabice = cca 0,3 m2 = potřeba cca 3,3 krabic na m2</li>
                        <li>4 malé krabice jsou dodávány ve vnější krabici 40x60x21 cm</li>
                        <li>160 malých krabic na paletě / 40 vnějších krabic</li>
                    </ul>
                </div>
            </div>

            <div className="productBox pb-5 d-md-flex justify-content-center gap-5">
                <div className="item-2">
                    <h3 className="pb-3">Velká krabička</h3>
                    <ul>
                        <li>Tlouštka mechu cca 1-2 cm;</li>
                        <li>Rozměry krabice: 40x60x21 cm</li>
                        <li>1 krabice kuličkového mechu: cca 1 m2</li>
                        <li>40 krabic na paletě</li>
                    </ul>
                </div>
                <div className="p-5">
                    <img src="/media/platmos-large.png" alt="velké balení plochého mechu" />
                </div>
            </div>

        </div>
    );
}

export default ProductPacking1;