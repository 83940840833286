window.onscroll = () => sampleFunction();

function sampleFunction() {

    let nav = document.getElementById("header");
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        nav.classList.add('scroll');
    } else {
        nav.classList.remove('scroll');
    }
}