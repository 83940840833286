import React, { useState } from "react";
import "./contactForm.scss";
import Cookies from 'js-cookie';

const csrfToken = Cookies.get('csrftoken');

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [status, setStatus] = useState('');

    // Funkce pro zpracování změn v inputu
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // Funkce pro odeslání formuláře
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://r-moss.cz/api/contact-form/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken, 
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setStatus('Formulář byl úspěšně odeslán!');
                setFormData({ name: '', email: '', phone: '', message: '' });  // Reset formuláře
            } else {
                setStatus('Došlo k chybě při odesílání formuláře.');
            }
        } catch (error) {
            console.error('Error:', error);
            setStatus('Došlo k chybě při odesílání formuláře.');
        }
    };

    return (
        <div className="contactForm">
            <div className="contactFormForm p-md-5 p-3">
                <h2 className="fw-bold text-primary pb-5">Kontaktní formulář</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-floating mb-3">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="floatingInputValueName" 
                            placeholder="Jméno"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        <label htmlFor="floatingInputValueName">Jméno a příjmení</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input 
                            type="email" 
                            className="form-control" 
                            id="floatingInputValueEmail" 
                            placeholder="E-mail"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="floatingInputValueEmail">E-mail</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="floatingInputValuePhone" 
                            placeholder="Telefon"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                        <label htmlFor="floatingInputValuePhone">Telefon</label>
                    </div>
                    <div className="form-floating mb-3">
                        <textarea 
                            className="form-control vyska" 
                            placeholder="Napište mi" 
                            id="floatingTextarea"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                        <label htmlFor="floatingTextarea">Napište nám...</label>
                    </div>
                    <button className="text-white bg-primary" type="submit">Odeslat zprávu</button>
                </form>
                {status && <p>{status}</p>}
            </div>
        </div>
    );
}

export default ContactForm;
