import { Routes, Route } from 'react-router-dom'

//PAGES
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Page404 from './pages/page404/Page404';
import Faq from './pages/faq/Faq';
import Glue from './pages/glue/Glue';
import Bolmos from './pages/bolmos/Bolmos';
import Platmos from './pages/platmos/Platmos';
import Home from './pages/home/Home';



//COMPONENTS
import Layout from './components/layout/Layout';
import Carousel from './components/carousel/Carousel';
import CarouselMain from './components/carousel/CarouselMain';


//STYLES
import "./styles/bootstrap.scss"
import "./styles/global.scss"
import "@fontsource/poppins"
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import CookiePolicy from './pages/cookiePolicy/CookiePolicy';


function App() {

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={[<CarouselMain name='R-moss s.r.o.' backgroundImage="/media/carousel04.jpg" />, <Home />]} />
          <Route path='/o-nas' element={[<Carousel name='O nás' backgroundImage="/media/carousel01.jpg" />, <About />]} />


          <Route path='/kulickovy' element={[<Carousel name='Bollmoss' backgroundImage="/media/carousel05.jpg" />, <Bolmos />]} />
          <Route path='/plochy' element={[<Carousel name='Platmoss' backgroundImage="/media/carousel02.jpg" />, <Platmos />]} />
          <Route path='/lepidlo' element={[<Carousel name='Lepidlo na mech' backgroundImage="/media/carousel03.jpg" />, <Glue />]} />

          <Route path='/faq' element={[<Carousel name='Často kladené dotazy' backgroundImage="/media/carousel02.jpg" />, <Faq />]} />
          <Route path='/kontakty' element={[<Carousel name='Kontakty' backgroundImage="/media/carousel02.jpg" />, <Contact />]} />
          < Route path='/zasady-cookies' element={[<Carousel name='Zásady cookies' />, <CookiePolicy />]}/>
          <Route path='*' element={[<Carousel name='Nenalezeno' />, <Page404 />]} />
        </Route>
      </Routes>
    </>


  );
}

export default App;
