import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="cookie-policy-container" style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <h1>Zásady používání cookies</h1>
      <p>
        Tyto Zásady používání cookies vysvětlují, jak naše webová stránka používá cookies a podobné technologie ke
        shromažďování a ukládání informací, když navštívíte naše stránky. Tímto dokumentem vám také poskytujeme
        informace o tom, jak cookies spravovat a případně zakázat.
      </p>

      <h2>Co jsou cookies?</h2>
      <p>
        Cookies jsou malé textové soubory, které jsou umístěny do vašeho zařízení (počítače, tabletu, mobilního
        telefonu) při návštěvě webové stránky. Cookies umožňují webovým stránkám rozpoznat vaše zařízení a pamatovat si
        určité informace o vaší interakci s webovými stránkami.
      </p>

      <h2>Typy cookies, které používáme</h2>

      <h3>1. Nezbytné cookies</h3>
      <p>
        Tyto cookies jsou klíčové pro to, aby naše webové stránky fungovaly správně. Umožňují základní funkce, jako je
        navigace mezi stránkami a přístup k zabezpečeným oblastem webu. Bez těchto cookies by webové stránky nemohly
        správně fungovat.
      </p>

      <h3>2. Analytické cookies</h3>
      <p>
        Analytické cookies nám pomáhají porozumět tomu, jak návštěvníci používají naše webové stránky. Shromažďují
        anonymní informace o tom, kolik uživatelů stránky navštívilo, které stránky byly nejčastěji zobrazovány a jak se
        uživatelé na stránkách pohybují. Tyto informace nám pomáhají zlepšovat funkčnost a uživatelskou přívětivost
        našeho webu.
      </p>

      <h3>3. Funkční cookies</h3>
      <p>
        Funkční cookies umožňují našim webovým stránkám zapamatovat si vaše volby a preference (například jazykové
        nastavení nebo region, ve kterém se nacházíte). Tyto cookies nám umožňují poskytovat vylepšené a více
        přizpůsobené služby.
      </p>

      <h3>4. Reklamní cookies</h3>
      <p>
        Reklamní cookies jsou používány k zobrazování relevantních reklam na základě vašich zájmů. Sledují pohyb uživatele
        na různých webových stránkách a shromažďují údaje o tom, jak uživatelé reagují na reklamy.
      </p>

      <h2>Jak můžete spravovat cookies?</h2>
      <p>
        Máte možnost kdykoli změnit nastavení cookies v prohlížeči, včetně jejich povolení, blokování nebo vymazání již
        uložených cookies. Zde je několik způsobů, jak můžete cookies spravovat:
      </p>

      <h3>1. Nastavení prohlížeče</h3>
      <p>
        Většina moderních webových prohlížečů vám umožňuje spravovat cookies přímo v nastavení prohlížeče. V menu
        prohlížeče najdete možnosti pro zablokování některých nebo všech cookies a také možnost vymazání cookies, které
        již byly uloženy. Postup se liší v závislosti na tom, jaký prohlížeč používáte (např. Chrome, Firefox, Safari,
        Edge).
      </p>

      <h3>2. Nastavení souhlasu s cookies na našich stránkách</h3>
      <p>
        Naše webová stránka vám při první návštěvě zobrazí lištu pro souhlas s cookies, kde si můžete nastavit, které
        typy cookies přijímáte a které ne.
      </p>

      <h2>Jak dlouho cookies uchováváme?</h2>
      <p>
        Doba uchovávání cookies závisí na typu cookies. Některé cookies (tzv. session cookies) jsou dočasné a zmizí,
        jakmile zavřete prohlížeč. Jiné cookies (tzv. persistent cookies) zůstávají ve vašem zařízení uložené i po
        zavření prohlížeče a jsou znovu aktivní při další návštěvě našich stránek. Doba, po kterou cookies zůstávají
        aktivní, závisí na jejich konkrétním účelu a může se lišit.
      </p>

      <h2>Změny zásad cookies</h2>
      <p>
        Tyto Zásady používání cookies můžeme čas od času aktualizovat, abychom zohlednili změny v používání cookies na
        našich stránkách. Jakékoliv změny těchto zásad budou zveřejněny na této stránce, abyste vždy měli aktuální
        informace o tom, jak cookies používáme.
      </p>

      <h2>Kontaktujte nás</h2>
      <p>
        Pokud máte jakékoliv dotazy týkající se používání cookies na našich webových stránkách, neváhejte nás kontaktovat
        na adrese:
      </p>
      <ul>
        <li><strong>E-mail:</strong> info@r-moss.cz</li>
        <li><strong>Telefon:</strong> +420 123 456 789</li>
      </ul>
    </div>
  );
};

export default CookiePolicy;
