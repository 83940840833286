import "./productIconPropertyBig.scss"
import { PropertyData } from "../../data";

const ProductIconPropertyBig = () => {



    return (
        <div className="productIconPropertyBig py-2 ">
            <div className="container">
                <div className="d-flex row pt-5  justify-content-between">
                    <h2 className="fw-bold text-primary col-md-4 pb-3">
                        Objevte krásu našich mechových produktů
                    </h2>
                    <p className="text-white col-md-4 pb-3">
                        Hledáte řešení pro vaši kancelář, obývací pokoj, restauraci nebo hotel. Naše mechové produkty dodávají každému prostředí nádech svěžesti a harmonie, ale zároveň slouží jako výrazný poutač.
                    </p>
                    <p className="text-white col-md-4 pb-3">
                        Existuje mnoho možností pro mech ve vašem interiéru, jako jsou mechové panely, mechové obrazy, mechové stropy a mobilní mechové stěny. Nejspeciálnější aplikace jsou možné s mechem ve vaší domácnosti nebo firmě.
                    </p>
                </div>
                <div className="text-white d-flex align-items-center justify-content-between py-4 row">
                    {PropertyData.map((item) => (
                        <div key={item.id} className="col-lg-4 d-flex gap-3 mb-5">
                            <img src={item.imgSrc} alt="" />
                            <div>
                                <div className="fw-bold mb-2">
                                    {item.heading}
                                </div>
                                <div className="">
                                    {item.text}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>

    );
}

export default ProductIconPropertyBig;