import { useEffect } from 'react';
import './backToTop.scss';

const BackToTop = () => {

    useEffect(() => {
        const handleScroll = () => {
            var backToTop = document.getElementById('backToTop');
            if (backToTop) {
                if (window.scrollY > 500) {
                    backToTop.classList.add('show');
                } else {
                    backToTop.classList.remove('show');
                }
            }
        };

        // Přidání event listeneru na scroll při montování komponenty
        window.addEventListener('scroll', handleScroll);

        // Odstranění event listeneru při odmontování komponenty
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // prázdné pole zajistí, že efekt se spustí jen jednou

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // přidá animaci při scrollování nahoru
        });
    }

    return (
        <button onClick={scrollToTop} id="backToTop">
            <img src="/media/arrow.svg" alt='ikona šipky'/>
        </button>
    );
}

export default BackToTop;
