import Accordion from "../../components/accordion/Accordion";
import HelmetSeo from "../../components/helmetSeo/HelmetSeo";
import { Link } from "react-router-dom";
import "./faq.scss";

const Faq = () => {

    
    return (
        <div className="faq p-md-5 p-3">
            <HelmetSeo
                title="Často kladené dotazy (FAQ)"
                description="Máte dotazy ohledně nákupu mechu nebo lepidel na mechové obrazy? Podívejte se na naše často kladené dotazy, kde najdete odpovědi na vše, co potřebujete vědět o objednávkách, platbách a montáži mechových obrazů."
                keywords="FAQ, často kladené dotazy, mechové obrazy, mech, lepidlo na mech, R-moss, montáž mechových obrazů, objednávky, platby"
                url="https://r-moss.cz/faq"
            />
            <div className="d-flex row accordionBox py-md-5 py-3">
                <div className="pb-md-5 pb-3 text-center">
                    <h2 className="text-white fw-bold">Seznam nejčastějších otázek</h2>
                </div>
                <div className="accordion bg-white p-md-5 p-2">
                    <Accordion />
                </div>
            </div>
            <div className="container">
                <h3 className="mt-4 py-5 text-primary fw-bold text-center">Nemohli jste najít odpověď na svou otázku?</h3>
                <p className="text-muted text-center">Pokud jste v naší FAQ sekci nenašli odpověď na vaši otázku nebo potřebujete další pomoc, rádi vám pomůžeme. <Link className="text-primary fw-bold" to="/kontakty">Kontaktujte nás</Link></p>
                <div className="my-5 d-flex justify-content-around align-items-center contactIcons">
                    <div className="">
                        <Link>
                            <img className="me-3 p-3" src="../../../icons/phone.png" alt="telefon" />
                        </Link>
                    </div>
                    <div className="">
                        <Link>
                            <img className="me-3 p-3" src="../../../icons/mail.png" alt="email" />
                        </Link>

                    </div>
                    <div className="">
                        <Link>
                            <img className="me-3 p-3" src="../../../icons/facebook-b.png" alt="facebook" />
                        </Link>
                    </div>
                    <div className="">
                        <Link>
                            <img className="me-3 p-3" src="../../../icons/instagram-b.png" alt="intagram" />
                        </Link>
                    </div>
                </div>


            </div>


        </div>

    );
}

export default Faq;