import "./home.scss";
import PhotoGallery from "../../components/photoGallery/PhotoGallery";
import ProductIconPropertyBig from "../../components/productIconProperty/ProductIconPropertyBig";
import HelmetSeo from "../../components/helmetSeo/HelmetSeo";



const Home = () => {
    return (
        <div className="">
            <HelmetSeo
                title="Přírodní mech pro váš interiér"
                description="R-moss.cz přináší přírodní mechové obrazy a produkty pro moderní a ekologický interiérový design. Objevte naší nabídku přírodního mechu a lepidel na mech, ideální pro kreativní a udržitelná řešení."
                keywords="mechové obrazy, přírodní mech, lepidlo na mech, ekologický design, interiérový design, R-moss, udržitelné produkty"
                url="https://r-moss.cz"
            />
            <div className="aboutSection container py-5">
                <div className="item item-1 pt-5">
                    <img src="/media/logo-main.png" alt="" />
                </div>
                <div className="item item-2 pt-5">
                    <h2 className="text-primary display-4">Proč R-moss?</h2>
                    <p className="fs-3">Unikátní mechové dekorace pro váš interiér</p>
                    <p>Mech je naše vášeň! V naší společnosti R-moss.cz již řadu let navrhujeme a vyrábíme unikátní a speciální mechové stěny, mechové obrazy a další exkluzivní mechové produkty.</p>
                    <div class="parent">
                        <div class="div1 product">
                            <img src="/media/bolmos-menu.png" alt="" />
                        </div>
                        <div class="div2 product">
                            <img src="/media/flatmos-menu.png" alt="" />
                        </div>
                        <div class="div3 product">
                            <img src="/media/glue-menu.png" alt="" />
                        </div>
                    </div>

                </div>

            </div>

            <ProductIconPropertyBig />

            <div className="container py-5">
                <PhotoGallery />
            </div>
        </div>
    );
}

export default Home;