import './photoGallery.scss'
import ImageModal from '../imageModal/ImageModal';

const PhotoGallery = () => {
    return (
        <div className="">
            <h2 className='my-5'>Nechte se inspirovat</h2>
            <div class="parent">
                <div class="div1">
                    <ImageModal
                        imageUrl="/media/gallery01.jpg"
                        altText="fotka produktu 1"
                    />
                </div>
                <div class="div2">
                    <ImageModal
                        imageUrl="/media/gallery02.jpg"
                        altText="fotka produktu 2"
                    />
                </div>
                <div class="div3">
                    <ImageModal
                        imageUrl="/media/gallery03.jpg"
                        altText="fotka produktu 3"
                    />
                </div>
                <div class="div4">
                    <ImageModal
                        imageUrl="/media/gallery04.jpg"
                        altText="fotka produktu 4"
                    />
                </div>
                <div class="div5">
                    <ImageModal
                        imageUrl="/media/gallery05.jpg"
                        altText="fotka produktu 5"
                    />
                </div>
                <div class="div6">
                    <ImageModal
                        imageUrl="/media/gallery06.jpg"
                        altText="fotka produktu 6"
                    />
                </div>
                <div class="div7">
                    <ImageModal
                        imageUrl="/media/gallery07.jpg"
                        altText="fotka produktu 7"
                    />
                </div>
                <div class="div8">
                    <ImageModal
                        imageUrl="/media/gallery08.jpg"
                        altText="fotka produktu 8"
                    />
                </div>
            </div>
        </div>

    );
}

export default PhotoGallery;