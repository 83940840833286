import "./productVariation.scss"
import PropTypes from "prop-types";

const ProductVariation = ({ name, produckImage }) => {

    return (
        <div className="productVariation d-flex flex-column align-items-center text-primary gap-3">
            <img src={produckImage} alt="Barva mechu" />
            <span>{name}</span>
        </div>
    );
}

ProductVariation.propTypes = {
    name: PropTypes.string.isRequired,   
    produckImage: PropTypes.string.isRequired 
};
export default ProductVariation;