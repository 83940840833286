import { Link } from "react-router-dom";
import HelmetSeo from "../../components/helmetSeo/HelmetSeo";
import ProductIconPropertyBig from "../../components/productIconProperty/ProductIconPropertyBig";
import "./about.scss"

const About = () => {
    return (
        <div className="about">
            <HelmetSeo
                title="O nás"
                description="Objevte příběh R-moss.cz – specialistů na mechové obrazy a produkty z přírodního mechu. Jsme hrdí na to, že přinášíme ekologická řešení pro interiérový design. Seznamte se s našimi produkty a hodnotami."
                keywords="R-moss, O nás, mechové obrazy, přírodní mech, lepidlo na mech, ekologický design, udržitelnost, interiérový design"
                url="https://r-moss.cz/onas"
            />
            <div className="d-flex justify-content-center pt-5 gap-5">
                <div className="">
                    <Link>Poslání</Link>
                </div>
                <div className="">
                    <Link>Cíle</Link>
                </div>
                <div className="">
                    <Link>Hodnoty</Link>
                </div>
            </div>
            <div className="container d-md-flex justify-content-between align-items-center gap-5">
                <div className=" py-5 companyImg">
                    <img src="/media/carousel08.jpg" alt="" />
                </div>
                <div className="py-5">
                    <h2 className="mb-lg-5 mb-3">Doplníme</h2>
                    <p>
                        Pellentesque convallis diam consequat magna vulputate malesuada. Cras a ornare elit. Nulla viverra pharetra sem, eget pulvinar neque pharetra ac.Lorem ipsum dolor sit amet, Ea consequuntur illum facere aperiam sequi optio consectetur.
                    </p>
                    <p>
                        Lorem Ipsum convallis diam consequat magna vulputate malesuada. Cras a ornare elit. Nulla viverra pharetra sem, eget pulvinar neque pharetra ac.
                    </p>
                    <button className="btn btn-secondary mt-lg-5">Dozvědět se více</button>

                </div>

            </div>
            <ProductIconPropertyBig />
            <div className="container d-md-flex justify-content-between align-items-center">



            </div>
        </div>
    );
}

export default About;