import "./carouselMain.scss";

const CarouselMain = () => {

    return (
        <div className="carouselMain">
            <div className="mainHeading text-white ">
                <span className="fs-4">MECHOVÉ PRODUKTY</span>
                <h1 className="display-2">R-moss</h1>
                <p className="fs-5">Příroda v každém detailu...</p>
            </div>
            <div id="carouselExampleFade" class="carousel  slide carousel-fade 100vh">
                <div class="carousel-inner">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-item active">
                        <img src="/media/carousel04.jpg" class="d-block w-100" alt="Obrázek komponenty carousel" />
                    </div>
                    <div class="carousel-item">
                        <img src="/media/carousel02.jpg" class="d-block w-100" alt="Obrázek komponenty carousel" />
                    </div>
                    <div class="carousel-item">
                        <img src="/media/carousel03.jpg" class="d-block w-100" alt="Obrázek komponenty carousel" />
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>

                {/*
        <div
            className="carousel text-white px-5 d-flex align-items-center"
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '100vh',
            }}
        >
            <img style={{filter:"invert(1)"}} src="/media/logo-main.png" alt="" />
            <h1 className="fw-bold" >{name}</h1>
        </div>*/}
            </div>


        </div>




    );
}

export default CarouselMain;