import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./accordion.scss";

const Accordion = () => {
    // Vytvoříme stav pro FAQ data
    const [faqData, setFaqData] = useState([]);

    // Použijeme useEffect pro načtení dat z API
    useEffect(() => {
        axios.get('/api/faqs/')
            .then(response => {
                setFaqData(response.data); // Nastavení dat do stavu
            })
            .catch(error => {
                console.error('There was an error fetching the data:', error);
            });
    }, []); // Prázdné pole zajistí, že se useEffect spustí pouze při prvním načtení komponenty

    return (
        <div className="accordion" id="accordionPanelsStayOpenExample">
            {faqData.map(faq => (
                <div key={faq.id} className="accordion-item mb-3">
                    <h2 className="accordion-header" id={`panelsStayOpen-heading${faq.id}`}>
                        <button 
                            className="accordion-button collapsed fs-6" 
                            type="button" 
                            data-bs-toggle="collapse" 
                            data-bs-target={`#id${faq.id}`} 
                            aria-expanded="false" 
                            aria-controls={`panelsStayOpen-collapse${faq.id}`}
                        >
                            {faq.question}
                        </button>
                    </h2>
                    <div 
                        id={`id${faq.id}`} 
                        className="accordion-collapse collapse" 
                        aria-labelledby={`panelsStayOpen-heading${faq.id}`}
                    >
                        <div className="accordion-body dark">
                            {faq.answer}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Accordion;
