const PurchaseInfo = () => {
    return (
        <div className="container py-5">
            <h2 className="fw-bold text-muted fs-5 mb-4 underlined-left">Jak objednat mechové produkty?</h2>
            <p><span className="fw-bold">Mechové produkty</span> si můžete objednat <span className="text-primary fw-bold">telefonicky</span> nebo vyplněním <span className="text-primary fw-bold">kontaktního formuláře. </span>
                Máte nějaké dotazy k našim produktům nebo jejich použití? Kontaktovat nás můžete
                samozřejmě také. Rádi vám poradíme!
            </p>
            <p className="fw-bold">
                Dodáváme pouze velkoobchodům.
            </p>
        </div>
    );
}

export default PurchaseInfo;