import React, { useState } from "react";
import "./imageModal.scss"

const ImageModal = ({ imageUrl, altText }) => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            {/* Obrázek, který bude klikatelný pro zvětšení */}
            <img
                src={imageUrl}
                alt={altText}
                className="imageModal img-thumbnail"
                style={{ cursor: "pointer", maxWidth: "800px" }}
                onClick={handleOpenModal}
            />

            {/* Bootstrap modální okno */}
            {showModal && (
                <div
                    className="imageModal modal fade show d-block"
                    role="dialog"
                    tabIndex="-1"
                    onClick={handleCloseModal}
                >
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleCloseModal}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <img
                                    src={imageUrl}
                                    alt={altText}
                                    className="img-fluid"
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ImageModal;
