import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Posune stránku na vršek při každé změně cesty
  }, [pathname]); // Kdykoliv se změní cesta, zavolá se scrollTo

  return null; // Komponenta nevrací žádné zobrazení
};

export default ScrollToTop;
