import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const HelmetSeo = ({ title, description, keywords, imageUrl="https://r-moss-sro-7662.rostiapp.cz/media/carousel04.jpg", url }) => {
    return (
        <Helmet>
            {/* Titulek stránky */}
            <title>{title} | R-moss.cz</title>
            
            {/* Meta tagy pro SEO */}
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="author" content="R-moss.cz" />

            {/* Open Graph (pro sociální sítě) */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={imageUrl} />
            <meta name="twitter:url" content={url} />
        </Helmet>
    );
}

// Definice PropTypes
HelmetSeo.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    keywords: PropTypes.string,
    imageUrl: PropTypes.string,
    url: PropTypes.string.isRequired,
};

export default HelmetSeo;