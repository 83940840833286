import "./carousel.scss"
import PropTypes from "prop-types";


const Carousel = ({ name, backgroundImage }) => {


    return (
        <div className="carousel  d-flex align-items-center">
            <div className="carouselImage">
                <img src={backgroundImage} alt="Obrázek komponenty carousel" />
            </div>
            <h1 className="fw-bold pt-5 mt-4 text-white px-4 px-md-5">{name}</h1>
        </div>
    );
};

// Definice PropTypes
Carousel.propTypes = {
    name: PropTypes.string.isRequired,          
    backgroundImage: PropTypes.string.isRequired 
};

export default Carousel;