import PhotoGallery from "../../components/photoGallery/PhotoGallery";
import Table from "../../components/table/Table";
import ProductVariation from "../../components/productVariation/ProductVariation";

import ProductPacking1 from "../../components/productPacking/ProductPacking1";
import InfiniteScroll from "../../components/autoScroll/InfiniteScroll";
import HelmetSeo from "../../components/helmetSeo/HelmetSeo";
import PurchaseInfo from "../../components/purchaseInfo/PurchaseInfo";


const Platmos = () => {
    return (
        <div className="platmos">
            <HelmetSeo
                title="Plochý mech"
                description="Objevte krásu plochých mechů na R-moss.cz. Ideální pro vytváření originálních mechových obrazů, dekorací a udržitelných interiérových řešení."
                keywords="plošné mechy, přírodní mech, dekorační materiály, mechové obrazy, R-moss, ekologický design, interiérový design"
                url="http://localhost:3000/plochy"
            />
            <div className="container">
                <div className="py-5 d-flex justify-content-between row">
                    <div className="col-lg-7">
                        <h2 className="pb-5">Mech <span className="text-primary fw-bold">Platmoss</span></h2>
                        <p>
                            Platmos je běžný v evropské přírodě. Díky svému charakteristickému plochému tvaru, který se pohybuje ve výšce od 1 do 3 cm, vnáší do každé místnosti klidnou texturu a přírodní barevnost.</p>
                        <p>
                            Náš Platmos je dostupný ve třech odstínech: 'Spring Green', 'Moss Green' a 'Forest Green'. Tyto barvy vytvářejí dokonalé pozadí pro navodění klidné a přirozené atmosféry v jakémkoli prostoru.
                        </p>

                    </div>
                    <div className="col-lg-5">
                        <h3 className="pb-3 text-primary">Barvy</h3>
                        <div className="d-flex justify-content-around">
                            <ProductVariation produckImage="/media/platmos-spring.png" name="SpringGreen" />
                            <ProductVariation produckImage="/media/platmos-moss.png" name="MossGreen" />
                            <ProductVariation produckImage="/media/platmos-forest.png" name="ForestGreen" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-5 container">
                <ProductPacking1 />
            </div>
            <div className="container">
                <Table />
            </div>
            <div className="mb-3">
                <InfiniteScroll />
            </div>
            <PurchaseInfo />
            <div className="container pb-5">
                <PhotoGallery />
            </div>
        </div>
    );
}

export default Platmos;