export const PropertyData = [
    {
        id: 1,
        imgSrc: "/icons/leaves-b.png",
        heading: "100% přírodní",
        text: "Naše mechové produkty jsou vyrobeny z pravého mechu, díky čemuž jsou 100% přírodní. Zachovají si svou přirozenou krásu po mnoho let."
    },
    {
        id: 2,
        imgSrc: "/icons/eye-b.png",
        heading: "Nápadité",
        text: "Zvláštní přitažlivost mechu spočívá v jeho přirozeném a uklidňujícím vzhledu. Jemná zelená a rozmanité textury mechu vytvářejí pocit spojení s přírodou."
    },
    {
        id: 3,
        imgSrc: "/icons/earth-b.png",
        heading: "Z udržitelných zdrojů",
        text: "Mech se sklízí udržitelným způsobem a připravuje se inovativním způsobem v naší vlastní továrně. To z něj dělá ekologickou volbu pro zelené dekorace a interiérový design."
    },
    {
        id: 4,
        imgSrc: "/icons/fire-b.png",
        heading: "Zpomalovač hoření",
        text: "Díky trvale protipožární úpravě lze náš mech použít v různých místnostech. Například veřejné budovy, kanceláře a domácnosti."
    },
    {
        id: 5,
        imgSrc: "/icons/mute-b.png",
        heading: "Absorbuje zvuky",
        text: "Naše mechové produkty pohlcují zvukové vibrace a snižují okolní hluk, což je výhodné zejména v rušných kancelářích, veřejných prostorách a rezidenčních prostředích."
    },
    {
        id: 6,
        imgSrc: "/icons/shapes-b.png",
        heading: "Snadno konfiguratelný",
        text: "Naše mechové produkty jsou vyrobeny z pravého mechu, díky čemuž jsou 100% přírodní. Zachovají si svou přirozenou krásu po mnoho let."
    },
    {
        id: 7,
        imgSrc: "/icons/can-b.png",
        heading: "Nenáročný na údržbu",
        text: "Naše mechové produkty nevyžadují téměř žádnou údržbu. Není potřeba prořezávání, zalévání ani jiná intenzivní péče, takže je ideální volbou do rušného prostředí."
    },
    {
        id: 8,
        imgSrc: "/icons/sun-b.png",
        heading: "Nevyžaduje denní světlo",
        text: "Naše mechové produkty jsou vyrobeny z pravého mechu, díky čemuž jsou 100% přírodní. Zachovají si svou přirozenou krásu po mnoho let."
    },
];
