import HelmetSeo from "../../components/helmetSeo/HelmetSeo";
import ProductVariation from "../../components/productVariation/ProductVariation";
import PurchaseInfo from "../../components/purchaseInfo/PurchaseInfo";

const Glue = () => {
    return (
        <div className="glue">
            <HelmetSeo
                title="Lepidlo na mech pro tvorbu mechových obrazů"
                description="Vysoce kvalitní lepidlo na mech od R-moss.cz zajišťuje snadnou a bezpečnou montáž přírodních mechových obrazů. Ideální pro tvorbu ekologických a designových interiérových řešení."
                keywords="lepidlo na mech, mechové obrazy, montáž mechu, přírodní mech, R-moss, ekologický design, interiérový design, lepidlo"
                url="https://r-moss.cz/lepidlo"
            />
            <div className="container py-5">
                <p>
                    Speciálně vyvinuto pro lepení připraveného mechu a/nebo dekoračních materiálů na různé druhy povrchů, jako je dřevo, kámen, stěny atd.
                    Lepidlo je šetrné k životnímu prostředí, na bázi bramborového škrobu, bílé tekuté pastové lepidlo.
                </p>
            </div>
            <div className="container py-5">
                <h2>Balení obsahuje</h2>
                <div className="d-flex row">
                    <div className="col-md-7 col-12">
                        <span>Vlastnosti</span>
                        <ul>
                            <li>Po vytvrzení transparentní</li>
                            <li>Přibl. Na 1 m2 je potřeba 1,5 kg lepidla</li>
                            <li>Naneste v dostatečném množství na povrch hrubým hladítkem nebo štětcem</li>
                            <li>Čistěte mýdlem a vodou</li>
                            <li>Chraňte před mrazem, před použitím promíchejte</li>
                        </ul>
                    </div>
                    <div className="col-md-5 col-12">
                        <ProductVariation produckImage="/media/glue-menu.png" name="Lepidlo na mech" />
                    </div>
                </div>
            </div>
            <PurchaseInfo title="Lepidlo na mech" />
        </div>
    );
}

export default Glue;