import "./infiniteScroll.scss";
import { PropertyData } from "../../data";

const InfiniteScroll = () => {

    return (
        <div className="infiniteScroll">
            <div class="wrapper">
                {PropertyData.map((item) => (
                    <div style={{ animationDelay:`calc(30s / 8 * (8 - ${item.id}) * -1)`}} className="item">
                        <img src={item.imgSrc} alt="ikony vlastností" />
                        <span>{item.heading}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default InfiniteScroll;